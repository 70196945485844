import axios from 'axios';
import qs from 'qs';
axios.defaults.timeout = 30000;
//axios.defaults.headers.common['token'] = sessionStorage.getItem('token');
// axios.defaults.baseURL = 'https://gl.pocketliveapp.com/'; //填写域名正式服
// axios.defaults.baseURL = 'https://gl.koudailive.com/'; //填写域名测试服
// axios.defaults.baseURL = '/api'; //填写域名
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; //填写域名


// let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
// let cancelToken = axios.CancelToken;
// let removePending = (config) => {
//     // console.log(config);
//     for (let i in pending) {
//         if (pending[i].url === axios.defaults.baseURL + config.url) { //在当前请求在数组中存在时执行取消函数
//             pending[i].f(); //执行取消操作
//             //pending.splice(i, 1); 根据具体情况决定是否在这里就把pending去掉

//         }
//     }
// }

//http request 拦截器
axios.interceptors.request.use(
  function(config) {
    // 这里的config包含每次请求的内容
    // 显示
    // removePending(config); //在一个axios发送前执行一下判定操作，在removePending中执行取消操作
    // // console.log(config.url);
    // config.cancelToken = new cancelToken(function executor(c) {//本次axios请求的配置添加cancelToken
    //     pending.push({
    //         // url: config.url,
    //         url: axios.defaults.baseURL + config.url,
    //         f: c
    //     });
    // });
    // console.log("请求拦截",config);
    let token = sessionStorage.getItem('token');
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          console.log('错误请求');
          break;
        case 401:
          console.log('未授权，请重新登录');
          break;
        case 403:
          console.log('拒绝访问');
          break;
        case 404:
          console.log('请求错误,未找到该资源');
          break;
        case 405:
          console.log('请求方法未允许');
          break;
        case 408:
          console.log('请求超时');
          break;
        case 500:
          console.log('服务器端出错');
          break;
        case 501:
          console.log('网络未实现');
          break;
        case 502:
          console.log('网络错误');
          break;
        case 503:
          console.log('服务不可用');
          break;
        case 504:
          console.log('网络超时');
          break;
        case 505:
          console.log('http版本不支持该请求');
          break;
        default:
          console.log(`连接错误${err.response.status}`);
      }
    } else {
      console.log('连接到服务器失败');
    }
    return Promise.resolve(err.response);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(data)).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
// 自封接口
export function api(url, data, method, headers) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      data,
      method,
      headers: headers,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((rej) => {
        reject(rej);
      });
  });
}
function parseStringToObject(arr) {
  arr = JSON.parse(arr)
  let obj = {};

    arr.forEach(item => {
        // 分割键值对，考虑到可能存在的多个冒号（如Content-Type）
        let [key, ...valueParts] = item.split(':');
        let value = valueParts.join(':'); // 如果有多个冒号，重新连接成完整的值

        // 移除首尾的空白字符并赋值到对象中
        obj[key.trim()] = value.trim();
    });
    return obj;
}
/**
 * 下面是获取数据的接口
 */
/**
 * 测试接口
 * 名称：exam
 * 参数：paramObj/null
 * 方式：fetch/post/patch/put
 */
export const server = {
  //列表
  privacy: function(paramObj) {
    return fetch('/AppHelpCenter/privacy', paramObj);
  },
  service: function(paramObj) {
    return fetch('/AppHelpCenter/service', paramObj);
  },
  curlExec: function(paramObj) {
    console.log(paramObj);
    return api(paramObj.host,paramObj.data,paramObj.option,parseStringToObject(paramObj.header))
    // return fetch('/Tools/curlExec', paramObj);
  },
};

export const axiosApi = {
  api,
  fetch,
  post,
  patch,
  put

}
