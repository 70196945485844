<template>
    <div>
        <div class="legendRankBody" ref="legendRankBody" v-if="rankListData.length > 0">
            <template v-if="tabIndex!=0">
                <table >
                    <thead>
                    <tr class="rankBodyHead">
                        <td>{{ $t('legend_tab_title_rank') }}</td>
                        <td>{{ $t('legend_tab_title_agencyid') }}</td>
                        <td>{{ $t('legend_tab_title_agencyname') }}</td>
                        <td>{{ $t('legend_tab_title_coins') }}</td>
                        <td>{{ $t('legend_tab_title_rewards') }}</td>
                        <td>{{ $t('legend_tab_title_target') }}</td>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item,index) in rankListData">
                            <tr class="rankBodyContent" :key="item.gid" v-if="index <= 6">
                                <td>{{ item.top | zeroFill}}</td>
                                <td>{{ item.gid }}</td>
                                <td>{{ item.gname }}</td>
                                <td >{{  addCommasToNumber(item.pt) }} </td>
                                <td v-if="item.rewardType == 2">{{ item.reward ? '$'+item.reward : ' '  }} </td>
                                <td v-else-if="item.rewardType == 1">{{ item.reward ? formatNumberK(item.reward ) : ' '}} </td>
                                <td v-else> </td>
                                <td>{{ item.target ? addCommasToNumber(item.target) : ' ' }} </td>
                            </tr>
                        </template>
                    <tr class="top7GetGift" v-if="rankListData.length > 7 && tabIndex == 3">{{ $t('legend_categoryc_top7reward') }} </tr>
                    <tr class="rankBodyContent" v-if="rankListData.length > 7 && tabIndex == 3">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td > </td>
                        <td> </td>
                        <td></td>
                    </tr>
                    <template  v-for="(item,index) in rankListData">
                        <tr class="rankBodyContent rankBodyContent2" :key="index" v-if="index > 6">
                            <td>{{ item.top | zeroFill}}</td>
                            <td>{{ item.gid }}</td>
                            <td>{{ item.gname }}</td>
                            <td>{{ addCommasToNumber(item.pt) }} </td>
                            <td v-if="item.rewardType == 2">{{ item.reward ? '$'+item.reward : ' '  }} </td>
                            <td v-else-if="item.rewardType == 1">{{ item.reward ? formatNumberK(item.reward ) : ' '}} </td>
                            <td v-else> </td>
                            <td>{{ item.target ? addCommasToNumber(item.target) : ' ' }} </td>
                        </tr>
                    </template>
                    
                    </tbody>
                </table>
            </template>
            <template v-else>
                <table >
                    <thead>
                    <tr class="rankBodyHead">
                        <td>{{ $t('legend_tab_title_rank') }}</td>
                        <td>{{ $t('legend_tab_title_agencyid') }}</td>
                        <td>{{ $t('legend_tab_title_agencyname') }}</td>
                        <td>{{ $t('legend_tab_title_coins') }}</td>
                        <td>{{ $t('legend_vip4s') }}</td>
                        <td>{{ $t('legend_vip5s') }}</td>
                        <td>{{ $t('legend_vip6s') }}</td>
                        <td>{{ $t('legend_appsponsor') }}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="rankBodyContent" v-for="(item,index) in rankListData" :key="index">
                        <td>{{ item.top | zeroFill}}</td>
                        <td>{{ item.gid }}</td>
                        <td>{{ item.gname }}</td>
                        <td>{{ addCommasToNumber(item.pt) }} </td>
                        <td>{{  item.vip4 ? item.vip4 : '' }} </td>
                        <td>{{ item.vip5 ? item.vip5 : '' }} </td>
                        <td>{{ item.vip6 ? item.vip6 : '' }} </td>
                        <td style="color: #fff;font-family:serif;direction: ltr;"> {{ item.top == 1 ? '√' : ' ' }}</td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </div>
        <div v-else class="loadingBox">
            <van-loading type="spinner" size="24px"/>
        </div>
        
    </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "legendRank",
  props:['rankListData','tabIndex','vipData'],
  filters:{
    zeroFill(num){
      if(num < 10){
        return '0' + num
      }else{
        return num
      }
    },
  },
  watch:{
    tabIndex(newValue){
        this.$refs.legendRankBody.scrollLeft = 0;
    }
  },
  mounted(){
  },
  methods: {
    addCommasToNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatNumberK(num) {
        if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + this.$t('legend_kdiamonds');
        } else {
            return num.toString ();
        }
    },
   
  },
};
</script>
