<template>
  <div class="turkeyBox" :class="client == 'iOS' ? '' : 'androidBox'">
    <header class="topBg">
    </header>
    <div class="timeBigBox">
        <p class="actStart" v-if="activeState == 1">{{ $t('turkeyDistance') }}</p>
        <p class="actStart" v-if="activeState == 2">{{ $t('turkeyStart') }}</p>
        <p class="actStart" v-if="activeState == 3">{{ $t('turkeyEnd') }}</p>
        <!-- <div class="timeBox" v-if="activeState == 1 || activeState == 2"> -->
        <div class="timeBox">
          <div>
            <span>{{ dayArr[0] }}</span>
            <span>{{ dayArr[1] }}</span>
            <div>{{ $t('turkeyDays') }}</div>
          </div>
          <span></span>
          <div>
            <span>{{ hourArr[0] }}</span>
            <span>{{ hourArr[1] }}</span>
            <div>{{ $t('turkeyHours') }}</div>
          </div>
          <span></span>
          <div>
            <span>{{ minuterArr[0] }}</span>
            <span>{{ minuterArr[1] }}</span>
            <div>{{ $t('turkeyMin') }}</div>
          </div>
        </div>
      </div>
    <div class="eventGiftsBox">
      <header>{{ $t('turkeyEventGifts') }}</header>
      <div class="giftsBox">
        <div>
          <div>
            <img src="../../assets/trUsa/gift1.png" alt="">
          </div>
          <p>{{ $t("xmas_gift1")  }}</p>
        </div>
        <div>
          <div>
            <img src="../../assets/trUsa/gift2.png" alt="">
          </div>
          <p>{{ $t('xmas_gift2') }}</p>
        </div>
        <div>
          <div>
            <img src="../../assets/trUsa/gift3.png" alt="">
          </div>
          <p>{{ $t('xmas_gift3') }}</p>
        </div>
        <div>
          <div>
            <img src="../../assets/trUsa/gift4.png" alt="">
          </div>
          <p>{{ $t('xmas_gift4') }}</p>
        </div>
      </div>
    </div>
    <!-- 西语葡语区 -->
    <template v-if="local == 1">
      <div class="rankTop3Box" :class="rankType == 2 ? 'zuanQianBox' : ''" v-if="rankType == 3 || rankType == 2">
        <div class="top2_3Box">
          <header>{{ $t("turkeyTop2_3")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_espt_supporter_top2-3')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_espt_host_top2-3')"></p>
        </div>
        <div class="top2_3Box top1Box">
          <header>{{ $t("turkeyTop1")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_espt_supporter_top1')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_espt_host_top1')"></p>
        </div>
        <div class="top2_3Box top4_10Box">
          <header>{{ $t("turkeyTop4_10")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_espt_supporter_top4-10')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_espt_host_top4-10')"></p>
        </div>
      </div>
    </template>
    <!-- 土语区 -->
    <template v-if="local == 2">
      <div class="rankTop3Box local2" :class="rankType == 2 ? 'zuanQianBox' : ''" v-if="rankType == 3 || rankType == 2">
        <div class="top2_3Box">
          <header>{{ $t("turkeyTop2_3")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_tr_supporter_top2-3')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_tr_host_top2-3')"></p>
        </div>
        <div class="top2_3Box top1Box">
          <header>{{ $t("turkeyTop1")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_tr_supporter_top1')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_tr_host_top1')"></p>
        </div>
        <div class="top2_3Box top4_10Box">
          <header>{{ $t("turkeyTop4_10")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_tr_supporter_top4-10')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_tr_host_top4-10')"></p>
        </div>
      </div>
    </template>
    <!-- 阿语区 -->
    <template v-if="local == 3">
      <div class="rankTop3Box local3RankTop3Box" :class="rankType == 2 ? 'zuanQianBox' : ''" v-if="rankType == 3 || rankType == 2">
        <div class="top2_3Box">
          <header>{{ $t("turkeyTop2_3")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_ar_supporter_top2-3')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_ar_host_top2-3')"></p>
        </div>
        <div class="top2_3Box top1Box">
          <header>{{ $t("turkeyTop1")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_ar_supporter_top1')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_ar_host_top1')"></p>
        </div>
        <div class="top2_3Box top4_10Box">
          <header>{{ $t("turkeyTop4_10")  }}</header>
          <div></div>
          <p v-if="rankType == 3" v-html="$t('xmas_ar_supporter_top4-10')"></p>
          <p v-if="rankType == 2" v-html="$t('xmas_ar_host_top4-10')"></p>
        </div>
      </div>
    </template>
    <div class="top20Rooms" v-if="rankType == 4">
      <header>{{ $t("turkeyTop4_10_2")  }}</header>
      <div></div>
    </div>
    <div class="rankListBox" :style="rankType == 4 ? 'padding-bottom:0' : ''">
      <div>
        <ul class="rankHeader">
          <li :class="rankType == 3 ? 'actRankHeader1':''" @click="changeRankType(3)">
            {{ $t("turkeySupporters")  }}
          </li>
          <li :class="rankType == 2 ? 'actRankHeader2':''" @click="changeRankType(2)">
            {{ $t("turkeyAnchors")  }}
          </li>
          <li :class="rankType == 4 ? 'actRankHeader3':''" @click="changeRankType(4)">
            {{ $t("turkeyParties")  }}
          </li>
        </ul>
      </div>
      <ul class="rankListItemBox">
        <li v-if="rankType == 3">
          <template v-if="anchorsTopObj.length > 0">
            <header class="rankTips" v-if="local == 1">{{ $t("xmas_rankingcountry_espt")  }}</header>
            <header class="rankTips" v-if="local == 2">{{ $t("xmas_rankingcountry_tr")  }}</header>
            <header class="rankTips" v-if="local == 3">{{ $t("xmas_rankingcountry_ar")  }}</header>
            <div class="rankTop3List">
              <div class="top2Box" @click="skipUserInfo(anchorsTopObj[1])">
                <div class="avatarFrame">
                  <template v-if="anchorsTopObj[1]">
                    <img v-if="anchorsTopObj[1] && anchorsTopObj[1].headIcon" :src="anchorsTopObj[1].headIcon" alt="">
                    <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                  </template>
                  <img v-else src="../../assets/trUsa/userFang.png" alt="">
                </div>
                <p class="userName" v-if="anchorsTopObj[1]">{{ anchorsTopObj[1].name}}</p>
                <div class="levelBox">
                    <span v-if="anchorsTopObj[1] && anchorsTopObj[1].wealthLv > 0" :class="['wealth', countClass(anchorsTopObj[1].wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ anchorsTopObj[1].wealthLv }}</span>
                    </span>
                    <span :class="['vip', 'vip' + anchorsTopObj[1].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="anchorsTopObj[1] && anchorsTopObj[1].vipLv > 0"></span>
                    <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="anchorsTopObj[1] && anchorsTopObj[1].vip == 1"></span>
                    <!-- <span :class="['psub', 'psub' + langs == 'ar' ? 'ar' : 'en']" v-if="anchorsTopObj[1].svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="anchorsTopObj[1]">{{anchorsTopObj[1].score | formatNumber }}</p>
              </div>
              <div class="top2Box topOneBox" @click="skipUserInfo(anchorsTopObj[0])">
                <div class="avatarFrame">
                  <template v-if="anchorsTopObj[0]">
                      <img  v-if="anchorsTopObj[0] && anchorsTopObj[0].headIcon" :src="anchorsTopObj[0].headIcon" alt="">
                      <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                  </template>
                  <img v-else src="../../assets/trUsa/userFang.png" alt="">
                </div>
                <p class="userName" v-if="anchorsTopObj[0]">{{ anchorsTopObj[0].name}}</p>
                <div class="levelBox">
                    <span v-if="anchorsTopObj[0] && anchorsTopObj[0].wealthLv > 0" :class="['wealth', countClass(anchorsTopObj[0].wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ anchorsTopObj[0].wealthLv }}</span>
                    </span>
                    <span :class="['vip', 'vip' + anchorsTopObj[0].vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="anchorsTopObj[0] && anchorsTopObj[0].vipLv > 0"></span>
                    <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="anchorsTopObj[0] && anchorsTopObj[0].vip == 1"></span>
                    <!-- <span :class="['psub', 'psub' + langs == 'ar' ? 'ar' : 'en']" v-if="anchorsTopObj[0].svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="anchorsTopObj[0]">{{anchorsTopObj[0].score | formatNumber}}</p>
              </div>
              <div class="top2Box top3Box" @click="skipUserInfo(anchorsTopObj[2])">
                <div class="avatarFrame">
                  <template v-if="anchorsTopObj[2]">
                    <img  v-if="anchorsTopObj[2] && anchorsTopObj[2].headIcon" :src="anchorsTopObj[2].headIcon" alt="">
                    <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                  </template>
                  <img v-else src="../../assets/trUsa/userFang.png" alt="">
                </div>
                    <p class="userName" v-if="anchorsTopObj[2]">{{ anchorsTopObj[2].name}}</p>
                <div class="levelBox">
                  <span v-if="anchorsTopObj[2] && anchorsTopObj[2].wealthLv > 0" :class="['wealth', countClass(anchorsTopObj[2].wealthLv)  + (langs == 'ar' ? 'ar' : '')]">
                    <span>{{ anchorsTopObj[2].wealthLv }}</span>
                  </span>
                  <span :class="['vip', 'vip' + anchorsTopObj[2].vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="anchorsTopObj[2] && anchorsTopObj[2].vipLv > 0"></span>
                  <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="anchorsTopObj[2] && anchorsTopObj[2].vip == 1"></span>
                    <!-- <span :class="['psub', 'psub' + langs == 'ar' ? 'ar' : 'en']" v-if="anchorsTopObj[2].svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="anchorsTopObj[2]">{{anchorsTopObj[2].score | formatNumber}}</p>
              </div>
            </div>
            <div class="top3After">
              <template v-for="(item,index) in anchorsTopObj">
                <div class="top3AfterItem top4Item"  :key="index" v-if="index > 2" @click="skipUserInfo(item)">
                  <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                  <p class="pictureBox">
                      <img  v-if="item.headIcon" :src="item.headIcon" alt="">
                      <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                      <img class="top4toukuang" v-if="item.frameMinUrl" :src="item.frameMinUrl" alt="">
                  </p>
                  <div class="gifterRankingCenter">
                    <p class="userName">{{ item.name }}</p>
                    <p class="gradeIconBox">
                      <span v-if="item.wealthLv > 0" :class="['wealth', countClass(item.wealthLv)  + (langs == 'ar' ? 'ar' : '')]">
                        <span>{{ item.wealthLv }}</span>
                      </span>
                      <span :class="['vip', 'vip' + item.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="item.vipLv > 0"></span>
                      <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.vip == 1"></span>
                    <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.svip == 1"></span>
                    </p>
                  </div>
                  <p class="userMoney">{{item.score | formatNumber}}</p>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="null"></div>
          </template>
        </li>
        <li v-if="rankType == 2" class="anchorBox">
          <template v-if="gifterTopObj.length > 0">
            <header class="rankTips" v-if="local == 1">{{ $t("xmas_rankingcountry_espt")  }}</header>
            <header class="rankTips" v-if="local == 2">{{ $t("xmas_rankingcountry_tr")  }}</header>
            <header class="rankTips" v-if="local == 3">{{ $t("xmas_rankingcountry_ar")  }}</header>
            <div class="rankTop3List">
              <div class="top2Box" @click="skipUserInfo(gifterTopObj[1])">
                <div class="avatarFrame">
                    <template v-if="gifterTopObj[1]">
                      <img  v-if="gifterTopObj[1] && gifterTopObj[1].headIcon" :src="gifterTopObj[1].headIcon" alt="">
                      <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                    </template>
                    <img v-else src="../../assets/trUsa/fang.png" alt="">
                </div>
                <p class="userName" v-if="gifterTopObj[1]">{{ gifterTopObj[1].name}}</p>
                <div class="levelBox">
                    <span v-if="gifterTopObj[1] && gifterTopObj[1].glamourLv > 0" :class="['charm',  countCharmClass(gifterTopObj[1].glamourLv) + (langs == 'ar' ? 'ar' : '')]">{{ gifterTopObj[1].glamourLv }}</span>
                    <span :class="['vip', 'vip' + gifterTopObj[1].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="gifterTopObj[1] && gifterTopObj[1].vipLv > 0"></span>
                    <span :class="['sub', 'sub'  + (langs == 'ar' ? 'ar' : 'en')]" v-if="gifterTopObj[1] && gifterTopObj[1].vip == 1"></span>
                    <!-- <span :class="['sub', 'sub' + langs]" v-if="item.vip == 1"></span>
                    <span :class="['psub', 'psub' + langs]" v-if="item.svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="gifterTopObj[1]">{{gifterTopObj[1].score | formatNumber}}</p>
              </div>
              <div class="top2Box topOneBox" @click="skipUserInfo(gifterTopObj[0])">
                <div class="avatarFrame">
                  <template v-if="gifterTopObj[0]">
                      <img  v-if="gifterTopObj[0] && gifterTopObj[0].headIcon" :src="gifterTopObj[0].headIcon" alt="">
                      <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                  </template>
                    <img v-else src="../../assets/trUsa/fang.png" alt="">
                </div>
                      <p class="userName" v-if="gifterTopObj[0]">{{ gifterTopObj[0].name}}</p>
                <div class="levelBox">
                  <span v-if="gifterTopObj[0] && gifterTopObj[0].glamourLv > 0" :class="['charm',  countCharmClass(gifterTopObj[0].glamourLv) + (langs == 'ar' ? 'ar' : '')]">{{ gifterTopObj[0].glamourLv }}</span>
                    <span :class="['vip', 'vip' + gifterTopObj[0].vipLv+ (langs == 'ar' ? 'ar' : '')] " v-if="gifterTopObj[0] && gifterTopObj[0].vipLv > 0"></span>
                    <span :class="['sub', 'sub'  + (langs == 'ar' ? 'ar' : 'en')]" v-if="gifterTopObj[0] &&gifterTopObj[0].vip == 1"></span>
                    <!-- <span :class="['sub', 'sub' + langs]" v-if="item.vip == 1"></span>
                    <span :class="['psub', 'psub' + langs]" v-if="item.svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="gifterTopObj[0]">{{gifterTopObj[0].score | formatNumber }}</p>
              </div>
              <div class="top2Box top3Box"  @click="skipUserInfo(gifterTopObj[2])">
                <div class="avatarFrame">
                  <template v-if="gifterTopObj[2]">
                      <img  v-if="gifterTopObj[2] && gifterTopObj[2].headIcon" :src="gifterTopObj[2].headIcon" alt="">
                      <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                  </template>
                      <img v-else src="../../assets/trUsa/fang.png" alt="">
                </div>
                  <p class="userName" v-if="gifterTopObj[2]">{{ gifterTopObj[2].name}}</p>
                <div class="levelBox">
                  <span v-if="gifterTopObj[2] && gifterTopObj[2].glamourLv > 0" :class="['charm',  countCharmClass(gifterTopObj[2].glamourLv) + (langs == 'ar' ? 'ar' : '')]">{{ gifterTopObj[2].glamourLv }}</span>
                    <span :class="['vip', 'vip' + gifterTopObj[2].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="gifterTopObj[2] && gifterTopObj[2].vipLv > 0"></span>
                    <span :class="['sub', 'sub'  + (langs == 'ar' ? 'ar' : 'en')]" v-if="gifterTopObj[2] && gifterTopObj[2].vip == 1"></span>
                    <!-- <span :class="['sub', 'sub' + langs]" v-if="item.vip == 1"></span>
                    <span :class="['psub', 'psub' + langs]" v-if="item.svip == 1"></span> -->
                  </div>
                  <p class="zuanshiBox" v-if="gifterTopObj[2]">{{gifterTopObj[2].score | formatNumber }}</p>
              </div>
            </div>
            <div class="top3After">
              <template v-for="(item,index) in gifterTopObj">
                <div class="top3AfterItem top4Item" :key="index" v-if="index > 2" @click="skipUserInfo(item)">
                  <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                  <p class="pictureBox">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
                    <img class="top4toukuang" v-if="item.frameMinUrl" :src="item.frameMinUrl" alt="">
                  </p>
                  <div class="gifterRankingCenter">
                    <p class="userName">{{ item.name }}</p>
                    <p class="gradeIconBox">
                      <span v-if="item.glamourLv > 0" :class="['charm',  countCharmClass(item.glamourLv) + (langs == 'ar' ? 'ar' : '')]">{{ item.glamourLv }}</span>
                      <span :class="['vip', 'vip' + item.vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="item.vipLv > 0"></span>
                      <span :class="['sub', 'sub'  + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.vip == 1"></span>
                      <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.svip == 1"></span>
                    </p>
                  </div>
                  <p class="userMoney">{{item.score | formatNumber}}</p>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="null"></div>
          </template>
        </li>
        <li v-if="rankType == 4" class="roomTankBox">
          <template v-if="roomTopObj.length > 0">
            <header class="rankTips" v-if="local == 1">{{ $t("xmas_rankingcountry_espt")  }}</header>
            <header class="rankTips" v-if="local == 2">{{ $t("xmas_rankingcountry_tr")  }}</header>
            <header class="rankTips" v-if="local == 3">{{ $t("xmas_rankingcountry_ar")  }}</header>
            <div class="rankTop3List">
              <div class="top2Box">
                <div class="avatarFrame">
                  <img @click="toAppRoom(roomTopObj[1])" v-if="roomTopObj[1] && roomTopObj[1].headIcon" :src="roomTopObj[1].headIcon" alt="">
                  <template v-else>
                    <img v-if="roomTopObj[1]" @click="toAppRoom(roomTopObj[1])" src="../../assets/trUsa/defaultFang.png" alt="">
                    <img v-else src="../../assets/trUsa/fang.png" alt="">
                  </template>
                </div>
                <p class="userName" v-if="roomTopObj[1]">{{ roomTopObj[1].name}}</p>
                  <div class="zuanshiBox" v-if="roomTopObj[1]">{{roomTopObj[1].score | formatNumber}}</div>
              </div>
              <div class="top2Box topOneBox">
                <div class="avatarFrame">
                  <img @click="toAppRoom(roomTopObj[0])" v-if="roomTopObj[0] && roomTopObj[0].headIcon" :src="roomTopObj[0].headIcon" alt="">
                  <template v-else>
                    <img v-if="roomTopObj[0]" @click="toAppRoom(roomTopObj[0])" src="../../assets/trUsa/defaultFang.png" alt="">
                    <img v-else src="../../assets/trUsa/fang.png" alt="">
                  </template>
                </div>
                <p class="userName" v-if="roomTopObj[0]">{{ roomTopObj[0].name}}</p> 
                  <div class="zuanshiBox" v-if="roomTopObj[0]">{{roomTopObj[0].score | formatNumber}}</div>
              </div>
              <div class="top2Box top3Box">
                <div class="avatarFrame">
                  <img @click="toAppRoom(roomTopObj[2])" v-if="roomTopObj[2] && roomTopObj[2].headIcon" :src="roomTopObj[2].headIcon" alt="">
                  <template v-else>
                    <img v-if="roomTopObj[2]" @click="toAppRoom(roomTopObj[2])" src="../../assets/trUsa/defaultFang.png" alt="">
                    <img v-else src="../../assets/trUsa/fang.png" alt="">
                  </template>
                </div>
                <p class="userName" v-if="roomTopObj[2]">{{ roomTopObj[2].name}}</p>
                  <div class="zuanshiBox" v-if="roomTopObj[2]">{{roomTopObj[2].score | formatNumber }}</div>
              </div>
            </div> 
            <div class="top3After">
            <template v-for="(item,index) in roomTopObj">
              <div class="top3AfterItem top4Item" :key="index" v-if="index > 2">
                <p class="rankingNum">{{ item.top | zeroFillTop }}</p>
                <p class="pictureBox" @click="toAppRoom(item)">
                  <img v-if="item.headIcon" :src="item.headIcon" alt="">
                  <img v-else src="../../assets/trUsa/fang.png" alt="">
                </p>
                <div class="gifterRankingCenter">
                  <p class="userName">{{ item.name }}</p>
                </div>
                <p class="userMoney">{{ item.score | formatNumber }}</p>
              </div>
            </template>
          </div>
          </template>
          <template v-else>
            <div class="null"></div>
          </template>
        </li>
      </ul>
    </div>
    <div class="selfInfo" v-if="rankType == 3 || rankType == 2">
      <!-- 收礼自己的信息 -->
      <div class="top3AfterItem" v-if="rankType == 3 && anchorsSelfInfo">
        <p class="rankingNum">{{ anchorsSelfInfo.top | topFilter}}</p>

        <p class="pictureBox">
          <img v-if="anchorsSelfInfo.headIcon" :src="anchorsSelfInfo.headIcon" alt="">
          <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
          <img class="top4toukuang" v-if="anchorsSelfInfo.frameUrl" :src="anchorsSelfInfo.frameUrl" alt="">
        </p>
        <div class="gifterRankingCenter">
          <p class="userName">{{ anchorsSelfInfo.name }}</p>
          <p class="gradeIconBox">
            <span v-if="anchorsSelfInfo.wealthLv > 0" :class="['wealth', countClass(anchorsSelfInfo.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
              <span>{{ anchorsSelfInfo.wealthLv }}</span>
            </span>
            <span :class="['vip', 'vip' + anchorsSelfInfo.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="anchorsSelfInfo.vipLv > 0"></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="anchorsSelfInfo.vip == 1"></span>
            <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="anchorsSelfInfo.svip == 1"></span>
          </p>
        </div>
        <p class="userMoney">{{ anchorsSelfInfo.score | formatNumber}}</p>
      </div>
      <!-- 送礼自己的信息 -->
      <div class="top3AfterItem gifterSelfBox" v-if="rankType == 2 && gifterSelfInfo">
        <p class="rankingNum">{{ gifterSelfInfo.top | topFilter}}</p>
        <p class="pictureBox">
          <img v-if="gifterSelfInfo.headIcon" :src="gifterSelfInfo.headIcon" alt="">
          <img v-else src="../../assets/trUsa/anchorNotBg.png" alt="">
          <img class="top4toukuang" v-if="gifterSelfInfo.frameUrl" :src="gifterSelfInfo.frameUrl" alt="">
        </p>
        <div class="gifterRankingCenter">
          <p class="userName">{{ gifterSelfInfo.name }}</p>
          <p class="gradeIconBox">
            <span v-if="gifterSelfInfo.glamourLv > 0" :class="['charm',  countCharmClass(gifterSelfInfo.glamourLv) + (langs == 'ar' ? 'ar' : '')]">{{ gifterSelfInfo.glamourLv }}</span>
            <span :class="['vip', 'vip' + gifterSelfInfo.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="gifterSelfInfo.vipLv > 0"></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="gifterSelfInfo.vip == 1"></span>
            <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="gifterSelfInfo.svip == 1"></span>
          </p>
        </div>
            <p class="userMoney">{{gifterSelfInfo.score | formatNumber }}</p>
      </div>
    </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "turkey",
  data() {
    return {
      // 活动id
      activityId:0,
      // 活动信息
      activityObj:{},
      // 榜单类型
      rankType: 3,
      // 送礼榜单信息
      anchorsTopObj:[],
      // 收礼榜单信息
      gifterTopObj:[],
      // 送礼个人信息
      anchorsSelfInfo:null,
      // 个人榜收礼个人信息
      gifterSelfInfo:null,
      page:0,
      size:20,
      // 房间榜
      roomTopObj:[],
      // 天数
      dayArr:[0,0],
      // 小时数
      hourArr:[0,0],
      // 分钟数
      minuterArr:[0,0],
      // 活动状态
      activeState:1,
      // 国家地区  1西语葡语区 2土语区 3阿语区
      local:1
    }
  },
  filters:{
    formatNumber(num) {
      if(num){
        if (num > 1000000) {
            return Math.trunc((num / 1000000) * 10) / 10 + 'M';
        } else if (num >= 1000) {
            return Math.trunc((num / 1000) * 10) / 10 + 'K';
        } else {
            return num.toString ();
        }
      }
      
    },
    topFilter(top){
      // if(top > 20){
      //   return '20+';
      // }else 
      if(top == 0){
        return '--'
      }else{
        return top > 9 ? top : '0' + top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
  },
  created() { 
    this.activityId = this.$route.query.activityId;
    this.local = this.$route.query.local || 1;
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 
    
  },
  methods: {
    changeRankType(type) {
      this.rankType = type;
      if(type != 4){
        this.getGifterSelfRankInfo();
        this.getSupporterRankingInfo();
      }else{
        this.getRoomRankingInfo()
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
      //    this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjQ5NDQ0N30.toInbRHaQKUfK73mTeDuSXyKmY-dEUZRiMKa_G7JNmw';
      // this.uid = '793228'; 
      // this.headers = {
      //     fid: '1001',
      //     os: 1,
      //     lang: 'zh-CN',
      //     version: '3.0',
      //     deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
      // }
      // let langs = 'en'
      // this.langs = 'en'
      // let lang = 'zh_CN'
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.title = this.$t('xmas_title')
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/modulerank/cfg?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
          this.activityObj = data.data.cfgList.find((item)=>{
            return item.id == this.activityId
          })

          // this.luckScore = data.data.luckScore;
          if(this.activityObj && this.activityObj.endTime > new Date().getTime() && this.activityObj.startTime < new Date().getTime()) {
            this.activeState = 2;
            // 设置倒计时
            this.countActivityTime()
            // 获取自己的排名信息  送礼
            this.getGifterSelfRankInfo()
            // 获取送礼榜单
            this.getSupporterRankingInfo()
        } else if(this.activityObj && this.activityObj.startTime > new Date().getTime()){

            this.activeState = 1;
            // 设置倒计时
            this.countActivityTime()
            // 获取自己的排名信息  送礼
            this.getGifterSelfRankInfo()
            // 获取送礼榜单
            this.getSupporterRankingInfo()
        } else {
            this.activeState = 3;
            // 获取自己的排名信息  送礼
            this.getGifterSelfRankInfo()
            // 获取送礼榜单
            this.getSupporterRankingInfo()
        }
      }
      });
    },
    // 计算活动时间
    countActivityTime(){
      let endTime = 0;
      if(this.activeState == 1){
        this.countdown(this.activityObj.startTime)
      }else{
        this.countdown(this.activityObj.endTime)
      }
      
      if(new Date().getTime() > this.activityObj.startTime && new Date().getTime() > this.activityObj.endTime){
        this.countdown(this.activityObj.endTime)
      }

    },
    // 根据时间戳转换倒计时
    countdown(endTime) {
      const now = new Date().getTime();
        const distance = endTime - now;
        console.log(distance);
        // const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 )) > 99 ? 99 : Math.floor(distance / (1000 * 60 * 60 )));
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24 )));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        // const hours = this.zeroFill(days);
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));
        this.dayArr = days;
        this.hourArr = hours;
        this.minuterArr = minutes;
        // this.seconds = seconds;
    },
    // 获取自己送礼信息
    getGifterSelfRankInfo(){
      this.anchorsSelfInfo = [];
      this.gifterSelfInfo = [];
      let rankInfo = {
        cfgId:this.activityId,
      };
      let paramObjs = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/modulerank/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          this.rankType+
          "&cfgId=" +
          rankInfo.cfgId+
          "&type=" +
          this.rankType,
        data: "",
        header: this.headers,
      };
      this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
        if (data.code == 200) {
          if(this.rankType == 3){
            this.anchorsSelfInfo = data.data;
          }else{
            this.gifterSelfInfo = data.data;
          }
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },

    // 获取个人送礼/收礼榜单
    getSupporterRankingInfo(){
        let that = this
        let paramObjs = {
          option: 'get',
          host: `${this.$serviceIpJava}api/activity/modulerank/role/page?token=${that.token}&uid=${that.uid}&cfgId=${this.activityId}&page=${this.page}&size=${this.size}&rankType=${this.rankType}`,
          data: '',
          header: this.headers,
        };
        this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
          if (data.code == 200) {
            this.anchorsTopObj = data.data.list
            this.gifterTopObj = data.data.list
            if (this.rankType == 3) {
              this.anchorsTopObj = data.data.list
            } else {
              this.gifterTopObj = data.data.list
            }
            
          }
          else {
            Toast(data.message || this.$t('failed'))
          }
        })
    },
    // 获取房间榜单
    getRoomRankingInfo(){
        let that = this
        let paramObjs = {
          option: 'get',
          host: `${this.$serviceIpJava}/api/activity/modulerank/room/page?token=${that.token}&uid=${that.uid}&cfgId=${this.activityId}&page=${this.page}&size=${this.size}&rankType=${this.rankType}`,
          data: '',
          header: this.headers,
        };
        this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
          if (data.code == 200) {
            this.roomTopObj = data.data.list
          }else {
            Toast(data.message || this.$t('failed'))
          }
        })
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 跳转房间
    toAppRoom(item){
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
      } else if (this.client == 'Android') {
        nativeJs["languageRoom"](JSON.stringify(item.roomId));
      }
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.uid));
      }
    },
  }
};
</script>

<style scoped>
@import './index.css';

@import '../../assets/gradeIcon/gradeIcon.css';
</style>
