<template>
  <div class="legendBox"  @scroll="pageScroll">
    <div class="headBox">
      <header></header> 
      <span @click="changePopFlag(1)">{{ $t('legend_rule_button') }}</span>
      <span @click="changePopFlag(2)">{{ $t('legend_reward_button') }}</span>
    </div>
    <div class="legendRankBox">
      <div class="legendItem">
        <div class="legendRankHead" ref="tabBox">
          <span :class="tabIndex == 1 ? 'actTitle' : ''" @click="changeTab(1)" >{{ $t('legend_tab1') }}</span>
          <span :class="tabIndex == 2 ? 'actTitle' : ''" @click="changeTab(2)">{{ $t('legend_tab2') }}</span>
          <span :class="tabIndex == 3 ? 'actTitle' : ''" @click="changeTab(3)">{{ $t('legend_tab3') }}</span>
          <span :class="tabIndex == 0 ? 'actTitle' : ''" @click="changeTab(0)">{{ $t('legend_tab4') }}</span>
        </div>
        <div class="rankItemBox" >
          <rankItem ref="rankItemBox" :rankListData="rankListData" :tabIndex="tabIndex" :vipData="vipData"></rankItem>
        </div>
      </div>
    </div>
    <div class="popBox" v-if="popFlag > 0">
      <div class="closeBox" @click="changePopFlag(0)">
        <span class="closeBtn"></span>
      </div>
      <div class="rulesBox" v-if="popFlag == 1">
        <div class="rulesScrBox">
          <div class="rulesList">
            <p><span>1.</span><span>{{ $t('legend_rule_row1') }}</span></p>
            <p><span>2.</span><span>{{ $t('legend_rule_row2') }}</span></p>
            <p><span>3.</span><span>{{ $t('legend_rule_row3') }}</span></p>
            <p><span>4.</span><span>{{ $t('legend_rule_row4') }}</span></p>
            <p><span>5.</span><span>{{ $t('legend_rule_row5') }}</span></p>
            <p><span>6.</span><span>{{ $t('legend_rule_row6') }}</span></p>
            <p><span>7.</span><span>{{ $t('legend_rule_row7') }}</span></p>
            <p><span>8.</span><span>{{ $t('legend_rule_row8') }}</span></p>
            <!-- <p><span>9.</span><span>{{ $t('legend_rule_row9') }}</span></p> -->
         </div>
         <div class="rulesTableBox">
            <header>{{ $t('legend_rule_vipplan') }}</header>
            <div class="rulesTableItem">
              <div class="rulesTableTitle">
                <span>{{ $t('legend_rule_vipplan_title1') }}</span>
                <span>{{ $t('legend_rule_vipplan_title2') }}</span>
                <span>{{ $t('legend_rule_vipplan_title3') }}</span>
                <span>{{ $t('legend_rule_vipplan_title4') }}</span>
              </div>
              <div class="rulesTableBody">
                <span>2,000,000</span>
                <span>1</span>
                <span>-</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>3,000,000</span>
                <span>1</span>
                <span>1</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>5,000,000</span>
                <span>2</span>
                <span>1</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>8,000,000</span>
                <span>3</span>
                <span>2</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>10,000,000</span>
                <span>3</span>
                <span>2</span>
                <span>1</span>
              </div>
            </div>
            <footer>{{ $t('legend_rule_vipplan_note') }}</footer>
         </div>
        </div>
         
      </div>
      <div class="rulesBox giftBox" v-if="popFlag == 2">
        <div class="rulesScrBox">
          <header></header>
          <div class="giftListBox">
            <div class="giftItem" v-for="(item,index) in rewardsData" :key="index">
              <p>{{ item.title }}</p>
              <div class="giftTableTitle">
                <span>{{ $t('legend_reward_title1') }}</span>
                <span>{{ $t('legend_reward_title2') }}</span>
                <span>{{ $t('legend_reward_title3') }}</span>
                <span>{{ $t('legend_reward_title4') }}</span>
              </div>
              <div class="giftTableBody" v-for="(subItem,i) in item.data" :key="i">
                <span>{{ subItem.rank }}</span>
                <span>{{ subItem.rewards }}</span>
                <span>{{ subItem.minimumTarget }}</span>
                <span>{{ subItem.note }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>
 
<script>
import { Toast } from "vant";
import rankItem from "./rank.vue"
export default {
  name: "legendActive",
  components:{
    rankItem
  },
  data() {
    return {
      tabIndex:1,
      popFlag: 0,
      rewardsData:[
      ],
      // 避免重复请求
      notFast: true,
      page:0,
      round:1,
      size:300,
      // 避免重复请求
      sendFlag: true,
      rankListData:[],
      vipData:[]
    }
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };

    // this.getUserInfo() 
    
  },
  methods: {
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast){
              this.page++;
              this.getActInfo();
          }
      }
    },
    // tab切换
    changeTab(index){
      this.page = 0;
      this.tabIndex = index;
      this.rankListData = [];
      this.getActInfo();
      
    },
    // 显示弹窗
    changePopFlag(type){
      this.popFlag = type;
      if(type != 0){
        // 禁止页面滚动
        this.stopScroll();
      }else{
        this.canScroll()
      }
      if(type == 2){
        this.rewardsData = [
        {
          title:this.$t('legend_tab1'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_1'),
              minimumTarget :this.$t('legend_reward_coins_1'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_3'),
              minimumTarget :this.$t('legend_reward_coins_2'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_1'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_1'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        },
        {
          title:this.$t('legend_tab2'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_2'),
              minimumTarget :this.$t('legend_reward_coins_2'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_2'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_2'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        },
        {
          title:this.$t('legend_tab3'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_3'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_3'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_3'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        }
        ]
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
      //    this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjQ5NDQ0N30.toInbRHaQKUfK73mTeDuSXyKmY-dEUZRiMKa_G7JNmw';
      // this.uid = '793228'; 
      // this.headers = {
      //     fid: '1001',
      //     os: 1,
      //     lang: 'zh-CN',
      //     version: '3.0',
      //     deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
      // }
      // let langs = 'en'
      // this.langs = langs
      // let lang = langs
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.title = this.$t('legend_title')
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      this.getActInfo();
      
    },

    // 获取活动信息
    async getActInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "post",
        host:
          this.$serviceIpJava +
          "api/code/legend/list?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: {
          page:this.page,
          round:this.round,
          size:this.size,
          type: this.tabIndex
        },
        lang: this.langs,
        header: this.headers,
      };

      if(this.sendFlag){
          this.sendFlag = false;
          this.notFast = false;
          let data =  await this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header)
          if (data.code == 200) {
            this.rankListData.push(...data.data.list);
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
            if(this.tabIndex == 0){
              this.getVipInfo()
            }
          }else{
            Toast(data.message);
          }
        this.sendFlag = true;
      }
      
    },
    // 过去vip数据
    async getVipInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/code/legend/cfg?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: {
        },
        lang: this.langs,
        header: this.headers,
      };
      let data =  await this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header)
      this.vipData = data.data.totalRankVips
      this.vipData.forEach((item, index, arr) => {
            item.score = Number(item.score)
      });
      // 为表单信息的每一项添加vip等级
      this.rankListData = this.rankListData.map((item)=>{
        return {...item,...this.countVip(item.pt)}
      })
    },
    countVip(pt){
      // 根据pt取出比pt小的
        let data = this.vipData.filter(element => {
            return element.score < Number(pt)
        })
        // 取出其中最大的
        const maxScoreItem = data.reduce((acc, item) => 
          item.score > acc.score ? item : acc, 
          { score: -Infinity } // 初始值
        );
        let vipLevel = {}
        // 为对象赋值 切割数组
        if(maxScoreItem.vips){
          maxScoreItem.vips.split(',').map((e)=> e.split(':')).map(e=>{
            vipLevel['vip' + e[0]] = Number(e[1])
          })
        }
        return vipLevel
    }
  }
};
</script>

<style scoped>
@import './index.css';

</style>
